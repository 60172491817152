@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';


$gold: (
  50: #fff8e1,
  100: #efdf9d, //ligher
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #a4903e, //reg
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);


mat.$grey-palette: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$my-theme-primary: mat.define-palette(mat.$grey-palette, 500, 300, 900);
$my-theme-accent: mat.define-palette($gold);
$my-theme-warn: mat.define-palette(mat.$deep-orange-palette, A200);

mat.$dark-theme-background-palette: (
  status-bar: black,
  app-bar:    map_get(mat.$grey-palette, 900),
  background: #3b3b3b,
  hover:      rgba(white, 0.04),
  card:       map_get(mat.$grey-palette, 800),
  dialog:     map_get(mat.$grey-palette, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get(mat.$grey-palette, 800),
  focused-button: $light-focused,
  selected-button: map_get(mat.$grey-palette, 900),
  selected-disabled-button: map_get(mat.$grey-palette, 800),
  disabled-button-toggle: black,
  unselected-chip: map_get(mat.$grey-palette, 700),
  disabled-list-option: black,
  tooltip: map_get(mat.$grey-palette, 700),
);


$my-theme: mat.define-dark-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

$primary: map-get($my-theme, primary);
$accent: map-get($my-theme, accent);
$warn: map-get($my-theme, warn);
$foreground: map-get($my-theme, foreground);
$background: map-get($my-theme, background);

$background-colour: map-get($background, background);

@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');


$custom-typography: mat.define-typography-config(
  $font-family: 'PT Serif, serif',
  $display-4:     mat.define-typography-level(114px, 114px, 300, $letter-spacing: -0.05em),
  $display-3:     mat.define-typography-level(58px, 58px, 400, $letter-spacing: -0.02em),
  $display-2:     mat.define-typography-level(47px, 50px, 400, $letter-spacing: -0.005em),
  $display-1:     mat.define-typography-level(36px, 42px, 400),
  $headline:      mat.define-typography-level(26px, 34px, 400),
  $title:         mat.define-typography-level(22px, 34px, 500),
  $subheading-2:  mat.define-typography-level(18px, 30px, 400),
  $subheading-1:  mat.define-typography-level(17px, 26px, 400),
  $body-2:        mat.define-typography-level(15px, 26px, 500),
  $body-1:        mat.define-typography-level(15px, 22px, 400),
  $caption:       mat.define-typography-level(14px, 22px, 400),
  $button:        mat.define-typography-level(16px, 16px, 500),
    // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(inherit, 1.0, 400)
);



.mat-toolbar.mat-primary {
  background: mat.get-color-from-palette($primary, 900);
  color: mat.get-color-from-palette($primary, 100)
}

.title1 {
  padding-bottom: 10px;
}

.title2 {
  padding-bottom: 10px;
}

.hinttext {
  text-transform: uppercase;
  font-size: smaller;
  color: mat.get-color-from-palette($primary, 400);
  margin: 10px;
}

.mat-button-toggle-checked {
  background-color: mat.get-color-from-palette($accent);
  color: mat.get-color-from-palette($accent, default-contrast);
}
