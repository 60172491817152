@use '@angular/material' as mat;
@import '~@angular/material/theming';

@import 'variables.scss';

@include mat.core($custom-typography);

@include mat.all-component-themes($my-theme); // the declaration of our custom material theme


html, body material-app, mat-sidenav-container {
  height: 100%;
}

body {
  margin: 0;
}

.mat-dialog-content {
  max-height: 80vh !important;
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label-content {
  white-space: normal !important;
}

.mat-toolbar {
  background: mat.get-color-from-palette($background);
}

//Stops the Flash of unstyled text
.mat-icon, mat-icon {
  font-family: 'Material Icons' !important;
}

//Fix mat-button with mat-icon alignment
.mat-icon-button .mat-button-wrapper {
  display: flex;
  justify-content: center;
}

.valign-center {
  display: inline-flex !important;
  vertical-align: middle;
  align-items: center;
}

.fullButton {
  width: 90%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;

  .mat-button-wrapper {
    width: 100%;
    text-align: center;
  }
}

.spacing {
  flex: 1 1 auto;
}

.holder {
  padding: 10px;
  background-color: mat.get-color-from-palette($background);
  margin-bottom: 130px;
}


.desc {
  font-weight: lighter;
}


.price {
  font-weight: bold;
  font-style: oblique;
  color: mat.get-color-from-palette($accent);
}

.menuitem {
  font-size: larger;
  margin-bottom: 5px;
}

.clickable {
  cursor: pointer;
}

.amount {
  text-align: right;
}

.option {
  font-size: small;
}

.total {
  margin-top: 10px;
  font-weight: bold;
  font-size: larger;
}

.foodpreview {
  width: 100%;
  object-fit: contain;
}

.backBottom {
  width: 90px;
  margin-right: 10px;
}


@mixin titlemixin {
  margin: 5px;
  font-weight: normal;
  font-variant-caps: all-small-caps;
}

.title {
  @include titlemixin;
  font-size: xx-large;
  margin-bottom: 15px;
}

.titleBase {
  @include titlemixin;
  font-size: x-large;
}


.floatPanel {
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 901;
}


.errormsg {
  margin: 5px;
  font-size: x-large;
  text-align: center;
  color: mat.get-color-from-palette($warn);
}

.spacer {
  flex: 1 1 auto;
}

//.spacer {
//  flex: 1 1 100%
//}

button.smallButtonWithIcon {
  height: 24px;

  mat-icon {
    height: 24px;
  }

  :host-context {
    height: 24px;
  }
}



td {
  padding: 0px;
}

